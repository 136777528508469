body{
    font-family: 'Montserrat', sans-serif;
}

.bg-grey{
    background-color: #efefef;
}

a, a:hover{
    color:#0f080c;
    font-weight: bold;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    background-color:#fddb00;
}

img.iconhomebanner{
    width:100px !important;
    margin: 0 auto;
}

input.empty{
    box-shadow: 0px 0px 7px 0px #fddb00;
}

.blue{
    color: #003ca6;
}

.whitecontent{
    color:#0f080c;
    background:#fff;
}

.btn {
    border-radius: 2.5rem;
    font-size: 1.5rem;
    padding: .5rem 1.75rem;
    font-weight: 700;
}

.btn-secondary, .btn-secondary:hover{
    background-color:#0f080c;
}

.text-secondary{
    color:#0f080c;
}

.btn-group label input[type="radio"]{
    display: none;
}

.btn-group label.btn {
    border-radius: 2.5rem;
    border-top-left-radius: 2.5rem;
    border-bottom-left-radius: 2.5rem;
    font-size: 1.25rem;
    padding: 0.45rem 1.5rem;
    font-weight: bold;
}

.itemservicecontent{
    height:240px
}


.btn-primary, .btn-primary:active, .btn-primary.disabled, .btn-primary:disabled {
    background-color:#fddb00;
    border-color:#fddb00;
    color:#000;
    font-weight:bold;
}

.page-item.active .page-link{
    color:#fff;
    background-color:#fddb00;
    border-color:#fddb00;
}

.page-link {
    color: #0f080c;
}

.btn-primary:hover{
    background-color:#fddb00;
    border-color:#fddb00;
    color:#000;
}

.h-150{
    height: 150px;
}

.bgimg{
    background-repeat: no-repeat;
    background-size:100% auto;
    background-position:center;
}

.bgimgpano{
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position:center;
}

img{
    max-width: 100%;
    width: auto;
}

p{
    font-weight: 500;
}

a:hover{
    text-decoration: none;
}

.bannerimg{
    width: 100%;
    height: auto;
}

.bannercontainer{
    height:auto;
}


.page-link:hover {
    color: #fddb00;
    background-color: #000;
    border-color: #000;
}

.carousel-indicators{
    margin-bottom: 0;
    display:none;
}

.bgblack{
   background-color:#0f080c;
}

.logowestern{
    height:60px;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
}

.logovimenca{
    height:60px;
}

.border-bottom-yellow{
     border-bottom: 4px solid #fddb00;
 }

.yellow{
    color:#fddb00;
}

.bgyellow{
    background-color: #fddb00;
}

.bgblue{
    background-color: #003ca6;
}

.bg-black{
    background: #000;
}

.text-black,.text-black:hover{
    color:#0f080c;
}

h1{
    font-size: 54px;
    font-weight: 300;
}


h2{
    font-size: 45px;
    font-weight: 500;
}

.card{
    border-radius: 1rem;
    box-shadow: 0px 1px 0 0 rgba(0,0,0,.125);
}

.shadow-left{
    box-shadow: -2px 0px 5px 0 rgba(0,0,0,.125);
}
.btn{
    border-radius: .5rem;
}

.hide{
    transform: translateX(100%);
    transition: transform 0.5s;
}

.show{
    transform: translateX(0%);
    transition: transform 0.5s;
}

.nav a{
    color:#0f080c;
    font-weight: bold;
}


header{
    width: 100%;
    top: 0;
    position: fixed;
    padding: 29px 0;
}

.sticky{
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
    width: 100%;
    z-index: 9;
    box-shadow: 0px 5px 5px 0 rgba(0,0,0,.125);
    padding-bottom: .25rem!important;
    padding-top: .25rem!important;
}

.sticky .c{
    height:55px;
}

.sticky .logovimenca{
    height:55px;
}

.togglemenu{
    right:0px;
    top:3px;
}

.sidemenu button{
    background: transparent;
    border:none;
}

.togglemenu button{
    background: transparent;
    border:none;
    color:#fddb00;
    padding:29px 10px;
}

.sticky .togglemenu{
    padding-top: 0px;
}

.sidemenu{
    position: fixed;
    z-index: 11;
    width: 25%;
    height: 100%;
    top: 0;
    right: 0;
}

.sidemenu svg{
    color: black
}

.subtitlespan{
    font-size: 110%;
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin: 0 auto;
}

.lds-ripple div {
    position: absolute;
    border: 4px solid #fddb00;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

.btn {
    border-radius: 2.5rem;
    font-size: 1.5rem;
    padding: 0.5rem 1.75rem;
    font-weight: bold;
}

.sucursalesspace .btn{
    border-radius: 0.25rem;
    font-size: 1rem;
    padding: 0.25rem 1.5rem;
}

.carousel-control-next, .carousel-control-prev{
    width:35px !important;
}

.btn-link:hover{
    text-decoration:none;
}

.carousel-control-next{
    right:5px;
}

.carousel-control-prev{
    left:5px;
}

@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

.vimencashlogo{
    max-width: 90%;
    width: auto;
}

footer .sellos img {
    height: 180px;
    margin: 10px;
}

@media (min-width: 1550px){
    .container {
        max-width: 75%;
    }
}

@media screen and (min-width: 1500px) {
    .bgimgpano {
        background-size: 100% auto;
    }
}

@media screen and (max-width: 1024px) {

    .bannercontainer{
        height:auto;
    }

    .sidemenu {
        width: 30%;
    }
}

@media screen and (max-width: 768px) {
    .h-100.bannerimg{
        height:auto;
    }

    .sidemenu {
        width: 60%;
    }

    h1{
        font-size: 40px;
    }

    .home-banner .carousel-control-prev,
    .home-banner .carousel-control-next {
        width: 40px !important;
        height: 40px;
        margin: auto 10px;
    }

    .home-banner .carousel-control-prev-icon,
    .home-banner .carousel-control-next-icon {
        width: 20px;
        height: 20px;
    }

    .services .carousel-control-prev,
    .services .carousel-control-next {
        width: 40px !important;
        height: 40px;
        margin: auto 10px;
    }

    .services .carousel-control-prev-icon,
    .services .carousel-control-next-icon {
        width: 20px;
        height: 20px;
    }
}

@media screen and (max-width: 600px) {
    .h-100.bannerimg{
        height: auto;
    }

    footer .sellos img {
        height: 120px;
        margin: 10px;
    }

    h1{
        font-size: 40px;
    }

    .sidemenu {
        width: 80%;
    }

    #mapspace{
        height: 90%;
        top: 109px;
        position: absolute;
        left: 0;
        width: 100%;
        padding-top:15px;
        background:#fff;
        z-index: 999999999;

    }
}
@media screen and (max-width: 550px) {
    .logowestern{
        height: 40px !important;
        width: auto;
    }

    .logovimenca{
        height: 40px !important;
        width: auto;
    }
}
@media screen and (max-width: 400px) {
    .sidemenu {
        width: 100%;
    }
}

.home-banner .carousel-control-prev,
.home-banner .carousel-control-next {
    width: 50px !important;
    height: 50px;
    background: rgba(0, 0, 0, 0.10);
    border-radius: 50%;
    margin: auto 15px;
    opacity: 0.9;
}

.home-banner .carousel-control-prev:hover,
.home-banner .carousel-control-next:hover {
    background: rgba(0, 0, 0, 0.25);
    opacity: 1;
}

.home-banner .carousel-control-prev-icon,
.home-banner .carousel-control-next-icon {
    width: 25px;
    height: 25px;
    filter: drop-shadow(0px 0px 3px rgba(255, 255, 255, 0.5));
}

.services .carousel-control-prev,
.services .carousel-control-next {
    width: 50px !important;
    height: 50px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    margin: auto 01px;
    opacity: 0.9;
}

.services .carousel-control-prev:hover,
.services .carousel-control-next:hover {
    background: rgba(0, 0, 0, 0.3);
    opacity: 1;
}

.services .carousel-control-prev-icon,
.services .carousel-control-next-icon {
    width: 25px;
    height: 25px;
    filter: brightness(0) invert(1);
    filter: drop-shadow(0px 0px 3px rgba(255, 255, 255, 0.5));
}