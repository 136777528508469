#diaspora-container{
  background-color: #010BDF;
  color: #fff;
  background-size:700px;
  background-repeat: no-repeat;
  background-position: top right;
}

#diaspora-container .main-top{
  background-position: right center;
  background-repeat: no-repeat;
  background-size: auto 100%;
}

#diaspora-container .second-top{
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 700px;
}

#diaspora-container .bold{
  font-weight: 900;
}

#diaspora-container .background-white{
  background-color: #fff;
}

#diaspora-container .bg-light-blue{
  background-color: #377EFF;
}

#diaspora-container .bg-yellow{
  background-color: #FAD03F;
  color: black;
}