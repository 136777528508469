body{
    font-family: "Montserrat",sans-serif;
}

@font-face {
    font-family: "HelveticaNeueLTStd-Cn";
    src: local('HelveticaNeueLTStd-Cn'), url(./resources/fonts/HelveticaNeueLTStd-Cn.otf) format('woff');
}
@font-face {
    font-family: "HelveticaNeueLTStd-BdEx";
    src: local('HelveticaNeueLTStd-BdEx'), url(./resources/fonts/HelveticaNeueLTStd-BdEx.otf) format('woff');
}

@font-face {
    font-family: "HelveticaNeueLTStd-BlkCnO";
    src: local('HelveticaNeueLTStd-BlkCnO'), url(./resources/fonts/HelveticaNeueLTStd-BlkCnO.otf) format('woff');
}

@font-face {
    font-family: "HelveticaNeueLTStd-BlkCn";
    src: local('HelveticaNeueLTStd-BlkCn'), url(./resources/fonts/HelveticaNeueLTStd-BlkCn.otf) format('woff');
}

@font-face {
    font-family: "HelveticaNeueLTStd-MdCn";
    src: local('HelveticaNeueLTStd-MdCn'), url(./resources/fonts/HelveticaNeueLTStd-MdCn.otf) format('woff');
}

.hnco{
    font-family: "HelveticaNeueLTStd-BlkCnO" !important;

}
.text-uppercase{
    text-transform: uppercase;
}

.vimencash * {
    font-family: "HelveticaNeueLTStd-Cn";
}

:root {
    --main-yellow: #FFDE00;
    --main-blue: #0057A0;
    --main-blue-alt: #29AAE1;
    --main-gray: #0000001A;
}

.helveticaitalic {
    font-family: "HelveticaNeueLTStd-BdEx";
    font-style: italic;
    letter-spacing: 0.7px;
}

.bg-blue-main {
    background-color: var(--main-blue);
}

.text-white{
    color: #fff;
}

.text-blue{
    color: #0057A0;
}

.text-gray{
    color: #585757 !important;
}

.main-heading{
    font-size: 550% !important;
    font-weight: bolder;
}

.vimencash .text-yellow{
    color: var(--main-yellow);
}

.vimencash .font-bold{
    font-weight: 800;
}
.bg-yellow-main {
    background-color: var(--main-yellow);
}

.text-main-yellow {
    color: var(--main-yellow);
    font-family: "HelveticaNeueLTStd-BdEx";
}

.text-main-blue {
    color: var(--main-blue);
    font-family: "HelveticaNeueLTStd-BdEx";
}

.text-center{
    text-align: center;
}
.rounded-btn{
    border-radius: 50px;
}

.text-xl{
    font-size: 38px ;
}

.vimencash svg {
    position: absolute;
    width: 100%;
    height: 5vw;
    /* set height to pixels if you want angle to change with screen width */
}
.vimencash .h-full {
    height: 100vh;
}
.vimencash-hero{
    background:#0055B8;
    display:flex;
    align-items: center;
    margin: auto;
    text-align: center;
    justify-content: center;
}
.vimencash .visually-hidden{
    display: none;
}
.vimencash .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transition-duration: .1s !important;
    transition-timing-function:linear;
}
.vimencash .videobanner{
    max-width: 800px;
    margin: auto;

}
.vimencash .phoneimg{
    margin-bottom: -200px;
    z-index: 1000;
    position: relative;
}

/* Fix for flickering carousel */
.vimencash .carousel-inner .carousel-item {
    opacity: 0.9;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
    position: absolute;
}
.vimencash .carousel-inner .carousel-item:first-of-type {
    position: relative;
}
.vimencash .carousel-inner .active {
    opacity: 1;
}
/* End of fix */

.vimencash .sucursalbtn{
    display: block;
    margin: auto;
    text-align: center;
    width: 100%;
    max-width: 450px;
    padding: 10px;
    font-weight: bold;
    font-family: "HelveticaNeueLTStd-MdCn";

}

body .mainLayout{
    font-family: 'Montserrat', sans-serif;
    background-color:#0f080c;
}

.mainLayout a, .mainLayout a:hover{
    color:#0f080c;
    font-weight: bold;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    background-color:#fddb00;
}

img.iconhomebanner{
    width:100px !important;
    margin: 0 auto;
}

input.empty{
    box-shadow: 0px 0px 7px 0px #fddb00;
}

.whitecontent{
    color:#0f080c;
    background:#fff;
}

.mainLayout .btn {
    border-radius: 2.5rem;
    font-size: 1.5rem;
    padding: .5rem 1.75rem;
    font-weight: 700;
}

.mainLayout .btn-secondary, .mainLayout .btn-secondary:hover{
    background-color:#0f080c;
    color:#fff;
}

.text-secondary{
    color:#0f080c;
}

.btn-group label input[type="radio"]{
    display: none;
}

.btn-group label.btn {
    border-radius: 2.5rem;
    border-top-left-radius: 2.5rem;
    border-bottom-left-radius: 2.5rem;
    font-size: 1.25rem;
    padding: 0.45rem 1.5rem;
    font-weight: bold;
}

.itemservicecontent{
    height:240px
}

.mainLayout .btn-primary, .mainLayout .btn-primary:active, .mainLayout .btn-primary.disabled, .mainLayout .btn-primary:disabled {
    background-color:#fddb00;
    border-color:#fddb00;
    color:#000;
    font-weight:bold;
}



.mainLayout .page-item.active .page-link{
    color:#fff;
    background-color:#fddb00;
    border-color:#fddb00;
}

.mainLayout .page-link {
    color: #0f080c;
}

.mainLayout .btn-primary:hover{
    background-color:#fddb00;
    border-color:#fddb00;
    color:#000;
}

.h-150{
    height: 150px;
}

.mainLayout .bgimg{
    background-repeat: no-repeat;
    background-size:100% auto;
    background-position:center;
}

.mainLayout .bgimgpano{
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position:center;
}

.mainLayout img{
    max-width: 100%;
}
.mainLayout p{
    font-weight: 500;
}
.mainLayout a:hover{
    text-decoration: none;
}

.mainLayout .bannerimg{
    width: 100%;
    height: auto;
}

.mainLayout .bannercontainer{
    height:auto;
}


.mainLayout .page-link:hover {
    color: #fddb00;
    background-color: #000;
    border-color: #000;
}

.mainLayout .carousel-indicators{
    margin-bottom: 0;
    display:none;
}

.mainLayout .bgblack{
    background-color:#0f080c;
}

.mainLayout .logowestern{
    height:60px;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;

}

.mainLayout .logovimenca{
    height:60px;
}

.mainLayout .border-bottom-yellow{
    border-bottom: 4px solid #fddb00;
}

.mainLayout .yellow{
    color:#fddb00;
}

.mainLayout .bgyellow{
    background-color: #fddb00;
}

.bgblue{
    background-color: #003ca6;
}

.mainLayout .bg-black{
    background: #000;
}

.mainLayout .text-black,.mainLayout .text-black:hover{
    color:#0f080c;
}

.mainLayout h1{
    font-size: 54px;
    font-weight: 300;
}


.mainLayout h2{
    font-size: 45px;
    font-weight: 500;
}

.mainLayout .card{
    border-radius: 1rem;
    box-shadow: 0px 1px 0 0 rgba(0,0,0,.125);
}

.mainLayout .shadow-left{
    box-shadow: -2px 0px 5px 0 rgba(0,0,0,.125);
}
.mainLayout .btn{
    border-radius: .5rem;
}

.mainLayout .hide{
    transform: translateX(100%);
    transition: transform 0.5s;
}

.mainLayout .show{
    transform: translateX(0%);
    transition: transform 0.5s;
}

.mainLayout .nav a{
    color:#0f080c;
    font-weight: bold;
}


.mainLayout header{
    width: 100%;
    top: 0;
    position: fixed;
    padding: 29px 0;
}

.mainLayout .sticky{
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
    width: 100%;
    z-index: 9;
    box-shadow: 0px 5px 5px 0 rgba(0,0,0,.125);
    padding-bottom: .25rem!important;
    padding-top: .25rem!important;
}

.mainLayout .sticky .logowestern{
    height: 55px;
}

.mainLayout .sticky .logovimenca{
    height: 55px;
}

.mainLayout .togglemenu{
    right:0px;
    top:3px;
}

.mainLayout .sidemenu button{
    background: transparent;
    border:none;
}

.mainLayout .togglemenu button{
    background: transparent;
    border:none;
    color:#fddb00;
    padding:44px 10px;
}

.mainLayout .sticky .togglemenu{
    padding-top: 0px;
}

.mainLayout .sidemenu{
    position: fixed;
    z-index: 11;
    width: 25%;
    height: 100%;
    top: 0;
    right: 0;
}

.subtitlespan{
    font-size: 110%;
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin: 0 auto;
}

.lds-ripple div {
    position: absolute;
    border: 4px solid #fddb00;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

.mainLayout .btn {
    border-radius: 2.5rem;
    font-size: 1.5rem;
    padding: 0.5rem 1.75rem;
    font-weight: bold;
}

.sucursalesspace .btn{
    border-radius: 0.25rem;
    font-size: 1rem;
    padding: 0.25rem 1.5rem;
}

.mainLayout .carousel-control-next, .carousel-control-prev{
    width:35px !important;
}

.mainLayout .btn-link:hover{
    text-decoration:none;
}

.mainLayout .carousel-control-next{
    right:5px;
}

.mainLayout .carousel-control-prev{
    left:5px;
}

.mainLayout .services .carousel-control-next{
    right:-75px;
}

.mainLayout .services .carousel-control-prev{
    left:-75px;
}

@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

@media screen and (min-width: 1500px) {

    .bgimgpano {
        background-size: 100% auto;
    }
}

@media screen and (max-width: 1024px) {

    .bannercontainer{
        height:auto;
    }

    .sidemenu {
        width: 30%;
    }
}

@media screen and (max-width: 768px) {
    .h-100.bannerimg{
        height:auto;
    }

    .sidemenu {
        width: 30%;
    }

    .mainLayout h1{
        font-size: 40px;
    }
}

@media screen and (max-width: 600px) {
    .h-100.bannerimg{
        height:auto;
    }

    .main-heading{
        font-size: 300% !important;
    }
    .mainLayout h1{
        font-size: 40px;
    }
}

@media screen and (max-width: 600px) {
    .mainLayout .sidemenu {
        width: 80%;
    }

    .logowestern{
        width:70%;
        margin-top:35px;
    }

    .logovimenca{
        width:78%;
        margin-top:35px;
    }

    #mapspace{
        height: 90%;
        top: 109px;
        position: absolute;
        left: 0;
        width: 100%;
        padding-top:15px;
        background:#fff;
        z-index: 999999999;

    }
}
@media screen and (max-width: 550px) {
    .logowestern{
        width:55%;
        margin-top:0px;
    }

    .logovimenca{
        width:54%;
        margin-top:20px !important;
    }
}
@media screen and (max-width: 400px) {
    .mainLayout .sidemenu {
        width: 100%;
    }

    .logowestern{
        width:55%;
        margin-top:0px;
    }

    .logovimenca{
        width:54%;
        margin-top:20px !important;
    }
}

