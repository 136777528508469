.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.services-logo div {
  width: auto;
  max-width: 50%;
  margin-right: 15px;
}

.services-logo div img {
  max-width: 120px;
  width: 100%;
}

.no-shadow.card{
  box-shadow:0 0 0 0 !important;
}

.btn-group label input[type="radio"]{
  display: none;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

li{
  margin-bottom: 10px;
}

ol, ul{
  margin: 10px 0;
}